$(function(){
  var state = false;
  var scrollpos;
  $('.toggle').on('click', function(){
    if(state == false) {
      $('body').css('overflow', 'hidden');
    scrollpos = $(window).scrollTop();
      state = true;
    } else {
      $('body').removeAttr('style');
      window.scrollTo( 0 , scrollpos );
      state = false;
    }
    $(this).toggleClass("active");
    $('.gnav').toggleClass("active");
  });
  $('a[href^=#]').click(function(){
    var headerHight = 0;
    var speed = 800;
    var href= $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top - headerHight;
    $("html, body").animate({scrollTop:position}, speed, "swing");
    $("html, body").scrollTop(position);
    return false;
  });
  $(window).scroll(function () {
    if ($(this).scrollTop() > 700) {
      $(".pagetop").fadeIn();
    }
    else{
      $(".pagetop").fadeOut();
    }
  });
  $('[class^="fz-change__btn"]').click(function(){
    $('[class^="fz-change__btn"]').removeClass('active');
    $(this).addClass('active');
  });
  $('.fz-change__btn--small').click(function(){
    $('html').css('font-size', '9px');
    $('.header .gnav').css('width', '70%');
  });
  $('.fz-change__btn--medium').click(function(){
    $('html').css('font-size', '10px');
    $('.header .gnav').css('width', '70%');
  });
  $('.fz-change__btn--large').click(function(){
    $('html').css('font-size', '11px');
    $('.header .gnav').css('width', '80%');
  });
  $('.nav-list__item > a:not(.btn-sub-menu)').hover(function() {
    $('.sub-menu').removeClass('active');
  });
  $('.btn-sub-menu').hover(function() {
    $(this).next('.sub-menu').addClass("active");
  });
});

$(document).ready(function () {
    if ($("body").hasClass('home')) {
      $('.login__box').removeClass('gray');
      $('.login__box__hdg').removeClass('gray');
    } else {
      $('.login__box').addClass('gray');
      $('.login__box__hdg').addClass('gray');
    }
  });

/* $(document).ready(function () {
  listNumber = $(".side-menu").innerHeight();
    if (listNumber > 60 ) {
      $('.side-menu').removeClass('one-item')
    } else {
      $('.side-menu').addClass('one-item')
    }
  }); */


// in-page link smooth scroll
  var headerHight = 100;
  $('a[href^=#]').click(function(){
    var speed = 800;
    var href= $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top - headerHight;
    // var position = target.offset().top;
    $("html, body").animate({scrollTop:position}, speed, "swing");
    // $("html, body").scrollTop(position);
    return false;
  });



var target = $('.swpm-login-form-submit').val('ログインする');

$(document).ready(function () {
    if ($("body").hasClass('logged-in')) {
      $('.login .inner').addClass('none');
      $('.login .btn02').addClass('none');
      $('.login').addClass('on');
    } else {
      $('.login .inner').removeClass('none');
      $('.login .btn02').removeClass('none');
      $('.login').removeClass('on');
    }
  });

$(document).ready(function () {
    if ($("body").hasClass('home')) {
      $('.login__box').removeClass('none');
    } else {
      $('.login__box').addClass('none');
    }
  });

$(function() {
  $('.download_btn').on('click', function(e){
    var href_url = $(this).attr('href');
    var href_name = $(this).attr('href').replace(/\\/g,'/').replace( /.*\//, '' );
    $(e.target).attr({
      download: href_name,
      href: href_url
    });
  });
});

//login plalceholder
let loginID = document.querySelector('.swpm-text-field.swpm-username-field');
if(loginID){
  loginID.placeholder = '会員ID';
}
let loginPass = document.querySelector('.swpm-text-field.swpm-password-field');
if(loginPass){
  loginPass.placeholder = 'パスワード';
}